import { useOnAuthStateChanged } from '@/app/_auth/auth-hooks'
import { RegisterOrgForm } from '@/app/_auth/steps/register-org-step'
import { RegisterPurposeForm } from '@/app/_auth/steps/register-purpose-step'
import { ConsentForm } from '@/app/account/_components/consent-dialog'
import React from 'react'

export type RegistrationForm = ConsentForm & RegisterOrgForm & RegisterPurposeForm

export type RegistrationFormContextValue = {
  orgValues: RegisterOrgForm | null
  setOrgValues: SetState<RegisterOrgForm | null>
  purposeValues: RegisterPurposeForm | null
  setPurposeValues: SetState<RegisterPurposeForm | null>
  consentValues: ConsentForm | null
  setConsentValues: SetState<ConsentForm | null>
}

const RegistrationFormContext = React.createContext<RegistrationFormContextValue>({
  orgValues: null,
  setOrgValues: () => {
    throw new Error('RegistrationFormContext.setOrgValues default value used')
  },
  purposeValues: null,
  setPurposeValues: () => {
    throw new Error('RegistrationFormContext.setPurposeValues default value used')
  },
  consentValues: null,
  setConsentValues: () => {
    throw new Error('RegistrationFormContext.setConsentValues default value used')
  },
})

export const RegistrationFormContextProvider = ({
  value,
  children,
}: React.PropsWithChildren<{ value: RegistrationFormContextValue }>) => (
  <RegistrationFormContext.Provider value={value}>{children}</RegistrationFormContext.Provider>
)

export function RegistrationFormProvider({ children }: React.PropsWithChildren) {
  const [orgValues, setOrgValues] = React.useState<RegistrationFormContextValue['orgValues']>(null)
  const [purposeValues, setPurposeValues] =
    React.useState<RegistrationFormContextValue['purposeValues']>(null)
  const [consentValues, setConsentValues] =
    React.useState<RegistrationFormContextValue['consentValues']>(null)

  const value = React.useMemo(
    () => ({
      orgValues,
      setOrgValues,
      purposeValues,
      setPurposeValues,
      consentValues,
      setConsentValues,
    }),
    [orgValues, purposeValues, consentValues],
  )

  useOnAuthStateChanged({
    onLogin: () => {
      setConsentValues(null)
      setOrgValues(null)
      setPurposeValues(null)
    },
  })

  return <RegistrationFormContextProvider value={value}>{children}</RegistrationFormContextProvider>
}

export function useRegistrationForm() {
  const context = React.useContext(RegistrationFormContext)

  if (!context) {
    throw new Error('useRegistrationForm must be used within RegistrationFormContext.Provider')
  }

  return context
}
