import { cn } from '@/lib'
import { cva, VariantProps } from 'class-variance-authority'
import * as React from 'react'

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & InputVariantProps
export type InputVariantProps = VariantProps<typeof inputVariants>

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, error, variant, text, ...props }, ref) => (
    <input
      tabIndex={0}
      type={type}
      className={cn(inputVariants({ error, variant, text }), className)}
      ref={ref}
      {...props}
    />
  ),
)
Input.displayName = 'Input'

export const inputVariants = cva(
  'flex w-full items-center placeholder:italic justify-between rounded border px-3 -outline-offset-1 focus:outline focus:outline-2 disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-input placeholder:text-input-placeholder',
        gray: 'bg-[#F9F7F7] placeholder:text-input-placeholder text-[#43304B]',
      },
      error: {
        true: 'border-error-accent focus:outline-error-accent',
      },
      height: {
        fixed: 'h-10 whitespace-nowrap',
        auto: 'max-h-[400px] min-h-16',
      },
      text: {
        sm: 'text-sm',
        md: 'text-base',
      },
    },
    defaultVariants: {
      variant: 'default',
      height: 'fixed',
      text: 'sm',
      error: false,
    },
    compoundVariants: [
      { variant: 'gray', error: false, className: 'border-[#AEA9B4] focus:outline-focus' },
      { variant: 'default', error: false, className: 'border-input-border focus:outline-focus' },
    ],
  },
)
