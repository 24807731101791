import { useAuth } from '@/app/_auth/auth-hooks'
import { Button } from '@/components/ui/button'
import { RouteLink } from '@/components/ui/route-link'
import { cn } from '@/lib'
import EmergenceLoader from '@/styles/animations/emergence-loader.json'
import { T, useTranslate } from '@tolgee/react'
import { cva } from 'class-variance-authority'
import { ArrowRight } from 'lucide-react'
import React from 'react'

const Lottie = React.lazy(() => import('lottie-light-react'))

const paddingXClasses = cva('px-8 h-md:xl:px-20 h-md:2xl:px-28')

type PageTemplateProps = ComponentProps<{
  title?: React.ReactNode
  icon?: React.ReactNode
  noPadding?: boolean
  loading?: boolean
}>

export const PageTemplate = ({
  children,
  className,
  title,
  icon,
  noPadding,
  loading,
}: PageTemplateProps) => {
  const { t } = useTranslate()
  const { isFirstLogin } = useAuth()

  if (loading)
    return (
      <div className='flex h-[100dvh] w-full items-center justify-center bg-content'>
        <Lottie animationData={EmergenceLoader} />
      </div>
    )

  return (
    <div
      className={cn(
        'flex w-full flex-col overflow-y-auto bg-content',
        !isFirstLogin && 'pl-[88px]',
      )}
    >
      {title && (
        <header
          className={paddingXClasses({
            className:
              'flex flex-col justify-between gap-3 border-b border-b-grey-100 py-4 md:flex-row md:items-center',
          })}
        >
          <span className='flex items-center gap-2'>
            {icon}
            <h1 className='pt-[5px] font-neue-machina text-lg font-normal uppercase leading-8 tracking-wide lg:text-xl'>
              {title}
            </h1>
          </span>
          <Button
            asChild
            variant='square-blue'
            className='px-4 py-3 text-sm font-[450] uppercase tracking-wide'
          >
            <RouteLink
              to='/billing/plan/enterprise-contact'
              title={t('shared.enterpriseUserButton.a11y')}
            >
              <T keyName='shared.enterpriseUserButton.title' />
              <ArrowRight className='h-5 w-5' />
            </RouteLink>
          </Button>
        </header>
      )}
      <section
        className={cn(
          !noPadding &&
            paddingXClasses({
              className: 'py-12',
            }),
          'flex flex-col gap-10',
          className,
        )}
      >
        {children}
      </section>
    </div>
  )
}

PageTemplate.H1 = function PageTemplateH1({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLHeadingElement>) {
  return (
    <h1
      className={cn('font-neue-machina text-[32px] font-bold leading-10 tracking-wide', className)}
      {...props}
    >
      {children}
    </h1>
  )
}

PageTemplate.H2 = function PageTemplateH2({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLHeadingElement>) {
  return (
    <h2 className={cn('font-neue-machina text-2xl font-bold tracking-wide', className)} {...props}>
      {children}
    </h2>
  )
}
