import { RegistrationFormProvider } from '@/app/_auth/steps/_common/registration-form-context'
import { CreateAccountStep } from '@/app/_auth/steps/create-account-step'
import { DeactivatedStep } from '@/app/_auth/steps/deactivated-step'
import { GetEmergenceUserStep } from '@/app/_auth/steps/get-emergence-user-step'
import { LinkProviderStep } from '@/app/_auth/steps/link-provider-step'
import { LoginStep } from '@/app/_auth/steps/login-step'
import { RegisterConsentStep } from '@/app/_auth/steps/register-consent-step'
import { RegisterOrgStep } from '@/app/_auth/steps/register-org-step'
import { RegisterPurposeStep } from '@/app/_auth/steps/register-purpose-step'
import React from 'react'

// 👇 step data

export enum AuthStep {
  Create = 'create',
  Login = 'login',
  LinkProvider = 'link-provider',
  Deactivated = 'deactivated',
  GetEmergenceUser = 'get-emergence-user',
  RegisterOrg = 'register-org',
  RegisterPurpose = 'register-purpose',
  RegisterConsent = 'register-consent',
}

const AUTH_STEP_COMPONENT: Record<AuthStep, React.FC> = {
  [AuthStep.Login]: LoginStep,
  [AuthStep.LinkProvider]: LinkProviderStep,
  [AuthStep.Create]: CreateAccountStep,
  [AuthStep.GetEmergenceUser]: GetEmergenceUserStep,
  [AuthStep.Deactivated]: DeactivatedStep,
  [AuthStep.RegisterOrg]: RegisterOrgStep,
  [AuthStep.RegisterPurpose]: RegisterPurposeStep,
  [AuthStep.RegisterConsent]: RegisterConsentStep,
}

// 👇 components

export function AuthStepNavigator({ step }: Readonly<{ step: AuthStep }>) {
  const AuthStepComponent = AUTH_STEP_COMPONENT[step]
  return (
    <RegistrationFormProvider>
      <AuthStepComponent />
    </RegistrationFormProvider>
  )
}

export const AuthGuard = ({
  step,
  children,
}: React.PropsWithChildren<{ step: AuthStep | null }>) =>
  step === null ? children : <AuthStepNavigator step={step} />
