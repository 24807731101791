import { auth } from '@/app/_auth/auth'
import { useAuth } from '@/app/_auth/auth-hooks'
import { AuthStep } from '@/app/_auth/auth-step-navigator'
import {
  AUTH_PROVIDER_DATA,
  AuthProviderID,
  LoginButton,
} from '@/app/_auth/steps/_common/login-buttons'
import { cn } from '@/lib'
import { ParamsTags, T, TranslateParams, TranslationKey, useTranslate } from '@tolgee/react'
import { linkWithCredential, OAuthProvider, signInWithPopup } from 'firebase/auth'
import React from 'react'

const EmergenceLogo = React.lazy(() =>
  import('@/components/emergence-logo').then((m) => ({ default: m.EmergenceLogo })),
)

export const AuthStepTemplate = ({
  children,
  hideLogo,
}: ComponentProps<{ hideLogo?: boolean }>) => (
  <main className='relative flex h-dvh w-full items-center justify-center'>
    <picture className='absolute left-0 top-0 -z-10 h-full w-full overflow-clip'>
      <source srcSet='/public/img/auth-bg.webp' type='image/webp' />
      <source srcSet='/public/img/auth-bg.jpg' type='image/jpeg' />
      <img
        src='/public/img/auth-bg.jpg'
        className='min-h-full min-w-full object-cover'
        alt='Login background graphic'
      />
    </picture>
    <section
      className='flex w-full max-w-lg flex-col items-center gap-8 rounded-2xl border border-white bg-[#f6f2f3] px-4
py-14 shadow-[0px_12px_24px_0px_#827C8C66] xs:px-14'
    >
      {!hideLogo && <EmergenceLogo className='w-40 p-0' />}
      {children}
    </section>
  </main>
)

// 👇 text

type TextProps = {
  className?: string
  keyName: TranslationKey
  params?: TranslateParams<ParamsTags>
}

AuthStepTemplate.H1 = function H1({ keyName, params, className }: TextProps) {
  return (
    <h1 className={cn('font-neue-machina text-[26px] font-light xs:text-[32px]', className)}>
      <T keyName={keyName} params={params} />
    </h1>
  )
}

AuthStepTemplate.H2 = function H2({ keyName, params, className }: TextProps) {
  return (
    <h2 className={cn('font-neue-machina text-2xl font-light text-[#252539]', className)}>
      <T keyName={keyName} params={params} />
    </h2>
  )
}

AuthStepTemplate.H3 = function H3({ keyName, params, className }: TextProps) {
  return (
    <h3 className={cn('font-circular-xx text-xl font-light text-[#252539]', className)}>
      <T keyName={keyName} params={params} />
    </h3>
  )
}

AuthStepTemplate.P = function P({ keyName, params, className }: TextProps) {
  return (
    <p className={className}>
      <T keyName={keyName} params={params} />
    </p>
  )
}

AuthStepTemplate.RequiredFieldsMessage = function RequiredFieldsMessage() {
  return (
    <AuthStepTemplate.P
      keyName='auth.steps.common.requiredFields'
      className='text-xs font-light italic'
    />
  )
}

// 👇 containers

AuthStepTemplate.Header = function Header({ children, className }: ComponentProps) {
  return (
    <div className={cn('flex flex-col items-center gap-3 text-center', className)}>{children}</div>
  )
}

AuthStepTemplate.ButtonRow = function ButtonRow({ children, className }: ComponentProps) {
  return <div className={cn('flex w-full gap-6', className)}>{children}</div>
}

// 👇 footer

AuthStepTemplate.Footer = function AuthFooter({
  variant = 'signIn',
}: {
  variant?: 'signIn' | 'signUp'
}) {
  const { setStep } = useAuth()

  if (variant === 'signUp')
    return (
      <AuthStepTemplate.P
        keyName='auth.footer.signUp'
        params={{
          i: <span className='text-[#252539]' />,
          a: (
            <button
              onClick={() => setStep(AuthStep.Create)}
              className='font-bold text-[#786E96] outline-[#a28fda]'
            />
          ),
        }}
      />
    )

  return (
    <AuthStepTemplate.P
      keyName='auth.footer.signIn'
      params={{
        i: <span className='text-[#252539]' />,
        a: (
          <button
            onClick={() => setStep(AuthStep.Login)}
            className='font-bold text-[#786E96] outline-[#a28fda]'
          />
        ),
      }}
    />
  )
}

// 👇 provider buttons

AuthStepTemplate.LoginButtons = function LoginButtons({
  providers = [AuthProviderID.Google, AuthProviderID.Microsoft],
}: {
  providers?: AuthProviderID[]
}) {
  const { t } = useTranslate()
  const { step, setStep, setAuthError, authError } = useAuth()

  const login = (providerId: AuthProviderID) => {
    const provider = new OAuthProvider(providerId)
    provider.setCustomParameters({ prompt: 'select_account' })

    signInWithPopup(auth, provider)
      .then((result) => {
        if (
          step === AuthStep.LinkProvider &&
          authError &&
          authError.code === 'auth/account-exists-with-different-credential'
        ) {
          const credential = OAuthProvider.credentialFromError(authError)
          if (credential) {
            linkWithCredential(result.user, credential).then(() => {
              setAuthError(null)
            })
          }
        }
      })
      .catch((error) => {
        console.error(error)
        if (error.code === 'auth/account-exists-with-different-credential') {
          setAuthError(error)
          setStep(AuthStep.LinkProvider)
        }
      })
  }

  return (
    <div className='flex w-full flex-col gap-3'>
      {providers.map((providerId) => {
        const { translationKeys, icon } = AUTH_PROVIDER_DATA[providerId]
        return (
          <LoginButton
            key={providerId}
            onClick={() => login(providerId)}
            title={t(translationKeys.a11y)}
          >
            {icon}
            <T keyName={translationKeys.title} />
          </LoginButton>
        )
      })}
    </div>
  )
}
