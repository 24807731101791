import { PageTemplate } from '@/app/_common/page-template'
import { AlertIcon } from '@/components/icons/alert-icon'
import { Button } from '@/components/ui/button'
import { Link } from '@tanstack/react-router'
import { T } from '@tolgee/react'

export const NotFoundPage = () => (
  <PageTemplate noPadding className='h-full items-center justify-center gap-8'>
    <AlertIcon />
    <PageTemplate.H1 className='max-w-[485px] text-center font-light text-error-accent'>
      <T keyName='notFound.title' />
    </PageTemplate.H1>
    <Button size='lg' asChild className='font-medium uppercase'>
      <Link to='/'>
        <T keyName='notFound.mainCTA' />
      </Link>
    </Button>
  </PageTemplate>
)
