import { cn } from '@/lib'
import { Link, type LinkProps, useRouter } from '@tanstack/react-router'

export function RouteLink({
  to,
  disabled,
  className,
  ...props
}: LinkProps & React.HTMLAttributes<HTMLAnchorElement>) {
  const { parseLocation } = useRouter()
  const isDisabled = disabled || to === parseLocation().pathname

  return (
    <Link
      to={to}
      disabled={isDisabled}
      className={cn(isDisabled && 'pointer-events-none !bg-grey-300 !text-gray-400', className)}
      {...props}
    />
  )
}
