import { useAuth } from '@/app/_auth/auth-hooks'
import { SideNavigation } from '@/app/_common/side-navigation'
import { NotFoundPage } from '@/app/not-found'
import { Toaster } from '@/components/ui/toaster'
import { createRootRoute, Outlet, useNavigate } from '@tanstack/react-router'
import React from 'react'

type SearchParams = {
  prompt?: string
  title?: string
  payment?: 'success' | 'error' | 'free-selected'
}

export const Route = createRootRoute({
  notFoundComponent: NotFoundPage,
  validateSearch: () => ({}) as SearchParams,
  component: RootRoute,
})

function RootRoute() {
  const { isFirstLogin } = useAuth()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (isFirstLogin) navigate({ to: '/billing/plan' })
  }, [isFirstLogin])

  return (
    <main className='relative flex h-[100dvh] overflow-y-clip font-circular-xx'>
      {!isFirstLogin && <SideNavigation />}
      <Outlet />
      <Toaster />
    </main>
  )
}
