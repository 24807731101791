import { auth } from '@/app/_auth/auth'
import { signOut } from 'firebase/auth'

export type RelativeUrl = `/${string}`

export const handleRequest = async (
  url: RelativeUrl,
  options: RequestInit = {},
): Promise<Response> => {
  try {
    const { token, clientId, absoluteUrl } = await getRequestData(url)

    const headers: RequestInit = {
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'X-Client-Id': clientId,
        ...options.headers,
      },
      ...options,
    }

    const response = await fetch(absoluteUrl, headers)

    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

async function getRequestData(url: RelativeUrl) {
  try {
    if (!auth.currentUser) throw new Error('LOGGED_IN_USER_NOT_FOUND')

    const apiBaseUrl = import.meta.env.VITE_API_BASE_URL

    if (!apiBaseUrl) throw new Error('API_BASE_URL_ENV_VAR_NOT_FOUND')

    const absoluteUrl = new URL(url, apiBaseUrl)
    const token = await auth.currentUser.getIdToken()
    const clientId = auth.currentUser.uid

    if (!token) throw new Error('FAILED_TO_GET_ACCESS_TOKEN')
    if (!clientId) throw new Error('FAILED_TO_GET_CLIENT_ID')

    return { absoluteUrl, token, clientId }
  } catch (error) {
    await signOut(auth)
    throw error
  }
}
