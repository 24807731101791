import { AuthProvider } from '@/app/_auth/auth-provider'
import { TooltipProvider } from '@/components/ui/tooltip'
import { routeTree } from '@/routeTree.gen'
import { addIntegration, init as initSentry } from '@sentry/react'
import { QueryClient, type QueryClientConfig, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { RouterProvider, createRouter } from '@tanstack/react-router'
import React from 'react'
import ReactGA from 'react-ga4'

// 👇 dynamic imports
const I18nProvider = React.lazy(() =>
  import('@/i18n/i18n-provider').then((m) => ({ default: m.I18nProvider })),
)

// 👇 TanStack Router
const router = createRouter({ routeTree })

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

// 👇 Google Analytics
ReactGA.initialize(import.meta.env.VITE_GOOGLE_ANALYTICS_ID)

// 👇 Sentry
initSentry({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  enabled: import.meta.env.VITE_SENTRY_ENABLED,
  debug: import.meta.env.VITE_SENTRY_DEBUG,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

import('@sentry/browser').then(
  ({ replayIntegration, browserTracingIntegration, extraErrorDataIntegration }) => {
    addIntegration(replayIntegration())
    addIntegration(browserTracingIntegration())
    addIntegration(extraErrorDataIntegration())
  },
)

const QUERY_CLIENT_DEFAULT_CONFIG: QueryClientConfig = {
  defaultOptions: {
    queries: { retry: false, refetchOnWindowFocus: false, refetchOnMount: true },
  },
}

export function AppRoot() {
  const [queryClient] = React.useState(() => new QueryClient(QUERY_CLIENT_DEFAULT_CONFIG))
  return (
    <QueryClientProvider client={queryClient}>
      <I18nProvider>
        <TooltipProvider>
          <AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>
        </TooltipProvider>
      </I18nProvider>
      {import.meta.env.VITE_DEV_MODE && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  )
}
