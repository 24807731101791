import { auth } from '@/app/_auth/auth'
import { useAuth, useOnAuthStateChanged } from '@/app/_auth/auth-hooks'
import { AuthGuard, AuthStep } from '@/app/_auth/auth-step-navigator'
import { AuthProviderID } from '@/app/_auth/steps/_components/login-buttons'
import type { EmergenceUser } from '@/app/_auth/steps/get-emergence-user-step'
import type { FirebaseError } from 'firebase/app'
import { signOut } from 'firebase/auth'
import React from 'react'

export const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const [step, setStep] = React.useState<AuthContextValue['step']>(AuthStep.GetEmergenceUser)
  const [user, setUser] = React.useState<AuthContextValue['user']>(null)
  const [authError, setAuthError] = React.useState<AuthContextValue['authError']>(null)
  const [isFirstLogin, setIsFirstLogin] = React.useState<AuthContextValue['isFirstLogin']>(false)

  const value = React.useMemo(
    () => ({
      step,
      setStep,
      user,
      setUser,
      authError,
      setAuthError,
      isFirstLogin,
      setIsFirstLogin,
    }),
    [step, user, authError, isFirstLogin],
  )

  useOnAuthStateChanged({
    onLogin: () => setStep(AuthStep.GetEmergenceUser),
    onLogout: () => {
      setUser(null)

      if (step !== AuthStep.Create && step !== AuthStep.Login) {
        setStep(AuthStep.Login)
      }
    },
  })

  return (
    <AuthContextProvider value={value}>
      {import.meta.env.VITE_DEV_MODE && <AuthStepDevTools />}
      <AuthGuard step={step}>{children}</AuthGuard>
    </AuthContextProvider>
  )
}

// 👇 auth context

export const AuthContextProvider = ({
  children,
  value,
}: React.PropsWithChildren<{ value: AuthContextValue }>) => (
  <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
)

interface AuthError extends FirebaseError {
  customData?: FirebaseError['customData'] & {
    email?: string
    _tokenResponse?: { providerId?: AuthProviderID; verifiedProvider?: AuthProviderID[] }
  }
}

export type AuthContextValue = {
  step: AuthStep | null
  setStep: SetState<AuthStep | null>
  user: EmergenceUser | null
  setUser: SetState<EmergenceUser | null>
  authError: AuthError | null
  setAuthError: SetState<AuthError | null>
  isFirstLogin: boolean
  setIsFirstLogin: SetState<boolean>
}

export const AuthContext = React.createContext<AuthContextValue>({
  user: null,
  step: AuthStep.GetEmergenceUser,
  setStep: () => {
    throw new Error('AuthContext.setStep default value used.')
  },
  setUser: () => {
    throw new Error('AuthContext.setUser default value used.')
  },
  authError: null,
  setAuthError: () => {
    throw new Error('AuthContext.setAuthError default value used.')
  },
  isFirstLogin: false,
  setIsFirstLogin: () => {
    throw new Error('AuthContext.setIsFirstLogin default value used.')
  },
})

// 👇 dev tools

const AuthStepDevTools = () => {
  const authSteps = Object.entries(AuthStep)
  const { setStep, step } = useAuth()

  React.useEffect(() => {
    console.log(
      `%cAUTH_STEP: ${step}`,
      'background:gold;color:black;padding:4px 6px;border-radius:3px;',
    )
  }, [step])

  if (step === null) return

  return (
    <div className=' absolute left-0 flex flex-col gap-3 bg-red-600 p-7'>
      <button onClick={() => setStep(null)} className='border border-black p-3'>
        BypassAuth
      </button>
      <button onClick={() => signOut(auth)} className='border border-black p-3'>
        SignOut
      </button>
      {authSteps.map(([step, key]) => (
        <button
          key={key}
          onClick={() => setStep(key as AuthStep)}
          className='border border-black p-3'
        >
          {step}
        </button>
      ))}
    </div>
  )
}
