import { useLogoutOnMount } from '@/app/_auth/auth-hooks'
import { AuthStepTemplate } from '@/app/_auth/steps/_common/auth-step-template'

export function CreateAccountStep() {
  useLogoutOnMount()

  return (
    <AuthStepTemplate>
      <AuthStepTemplate.Header>
        <AuthStepTemplate.H1 keyName='auth.welcomeTitle' />
        <AuthStepTemplate.H2 keyName='auth.steps.create' />
      </AuthStepTemplate.Header>
      <AuthStepTemplate.LoginButtons />
      <AuthStepTemplate.Footer />
    </AuthStepTemplate>
  )
}
