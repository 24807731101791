export const GA_EVENTS = {
  CATEGORY: {
    NAV: 'navigation',
    ACC: 'account_settings',
    EMAIL: 'email_form',
    STRIPE: 'stripe_purchase',
    HUBSPOT: 'hubspot_form',
  },
  ACTION: {
    BTN: 'button_click',
  },
} as const
