import { auth } from '@/app/_auth/auth'
import { AuthContext } from '@/app/_auth/auth-provider'
import { AUTH_PROVIDER_DATA, AuthProviderID } from '@/app/_auth/steps/_common/login-buttons'
import { type User, onAuthStateChanged, signOut } from 'firebase/auth'
import React from 'react'

// 👇 hooks

export function useAuth() {
  const authContext = React.useContext(AuthContext)

  if (!authContext) throw new Error('useAuth must be used within AuthContext.Provider')

  return authContext
}

export function useLogoutOnMount() {
  React.useEffect(() => {
    if (auth.currentUser) signOut(auth)
  }, [])
}

export function useOnAuthStateChanged({
  onLogin,
  onLogout,
}: {
  onLogin?: (user: User) => void
  onLogout?: () => void
}) {
  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) onLogin && onLogin(user)
      else onLogout && onLogout()
    })

    return unsubscribe
  }, [])
}

export function useAuthError(): {
  providerName: string
  verifiedProvider: AuthProviderID[]
  email: string
} {
  const { authError } = useAuth()

  if (!authError?.customData) throw new Error('AUTH_ERROR_DATA_NOT_FOUND')

  const { email, _tokenResponse } = authError.customData

  if (!email) throw new Error('AUTH_ERROR_EMAIL_NOT_FOUND')
  if (!_tokenResponse) throw new Error('AUTH_ERROR_TOKEN_RESPONSE_NOT_FOUND')

  const { providerId, verifiedProvider = [] } = _tokenResponse

  if (!providerId) throw new Error('AUTH_ERROR_PROVIDER_ID_NOT_FOUND')
  if (verifiedProvider.length === 0) throw new Error('AUTH_ERROR_VERIFIED_PROVIDER_NOT_FOUND')

  return { providerName: AUTH_PROVIDER_DATA[providerId].displayName, verifiedProvider, email }
}
