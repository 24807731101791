const USERS_BASE_PATH = '/api/v0/users'
type UsersBasePath = typeof USERS_BASE_PATH
type UsersAPIPath = UsersBasePath | `${UsersBasePath}/${string}`

export const USERS_API = {
  USERS: USERS_BASE_PATH,
  RUNS: `${USERS_BASE_PATH}/requestRuns`,
  DEACTIVATE: `${USERS_BASE_PATH}/deactivateAccount`,
  REACTIVATE: `${USERS_BASE_PATH}/reactivateAccount`,
  REGISTER: `${USERS_BASE_PATH}/register`,
  CONTACT: `${USERS_BASE_PATH}/contact`,
  PREFERENCES: `${USERS_BASE_PATH}/privacyPreferences`,
  API_KEY: `${USERS_BASE_PATH}/getApiKey`,
} satisfies Record<string, UsersAPIPath>

const PAYMENT_BASE_PATH = `${USERS_BASE_PATH}/payment`
type PaymentAPIPath = `${typeof PAYMENT_BASE_PATH}/${string}`

export const PAYMENT_API = {
  UPDATE_PAYMENT: `${PAYMENT_BASE_PATH}/updatePaymentMethod`,
  RATE_LIMIT: `${PAYMENT_BASE_PATH}/rateLimit`,
  SUBSCRIPTION: `${PAYMENT_BASE_PATH}/subscription`,
  CANCEL_SUBSCRIPTION: `${PAYMENT_BASE_PATH}/subscription/cancel`,
  PRODUCT_PRICES: `${PAYMENT_BASE_PATH}/productsAndPrices`,
  CHECKOUT: `${PAYMENT_BASE_PATH}/checkoutSession`,
  LIST_PAYMENTS: `${PAYMENT_BASE_PATH}/listPayments`,
  WORKFLOW_COST: `${PAYMENT_BASE_PATH}/workflows/cost`,
  DISABLE_WELCOME_MESSAGE: `${PAYMENT_BASE_PATH}/disableWelcomeMessage`,
} satisfies Record<string, PaymentAPIPath>

const ORCHESTRATORS_BASE_PATH = '/v0/orchestrators'
type OrchestratorsBasePath = `${typeof ORCHESTRATORS_BASE_PATH}/${string}`

export const ORCHESTRATORS_API = {
  WORKFLOWS: `${ORCHESTRATORS_BASE_PATH}/${import.meta.env.VITE_ORCHESTRATOR_AGENT_ID}/workflows`,
} satisfies Record<string, OrchestratorsBasePath>
