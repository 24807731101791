import { auth } from '@/app/_auth/auth'
import { useAuth } from '@/app/_auth/auth-hooks'
import { AuthStep } from '@/app/_auth/auth-step-navigator'
import { useRegistrationForm } from '@/app/_auth/steps/_common/registration-form-context'
import { AuthStepTemplate } from '@/app/_auth/steps/_components/auth-step-template'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label, LabelAsterisk } from '@/components/ui/label'
import {
  Select,
  SelectContent,
  SelectItemFromKey,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { GA_EVENTS } from '@/lib'
import { trimAndValidate } from '@/lib/form-utils'
import { T, TranslationKey, useTranslate } from '@tolgee/react'
import React from 'react'
import { Field, Form } from 'react-final-form'
import ReactGA from 'react-ga4'

export type RegisterOrgForm = {
  fullName: string
  orgName: string
  role: string
  orgSize: string
  industry: string
  otherIndustry?: string
}

type RegisterOrgFormKeys = keyof RegisterOrgForm
type RegisterOrgFormErrors = Partial<Record<RegisterOrgFormKeys, boolean | string>>

export function RegisterOrgStep() {
  const { t } = useTranslate()
  const { setStep } = useAuth()
  const { setOrgValues, orgValues } = useRegistrationForm()
  const refOtherIndustry = React.useRef<HTMLInputElement | null>(null)
  const refIndustry = React.useRef<HTMLButtonElement | null>(null)
  const industryOtherValue = t(OTHER_INDUSTRY_OPTION)

  const submit = (initialValues: RegisterOrgForm) => {
    const { values, errors } = trimAndValidate({
      initialValues,
      validate: (values) => validate({ values, industryOtherValue }),
    })

    if (errors) return errors

    ReactGA.event({
      action: GA_EVENTS.ACTION.BTN,
      category: GA_EVENTS.CATEGORY.ACC,
      label: 'submit_organization_registration_form',
    })

    setOrgValues(values)
    setStep(AuthStep.RegisterPurpose)
  }

  const back = (values: RegisterOrgForm) => {
    setOrgValues(values)
    setStep(AuthStep.Create)
  }

  const focusIndustryField = (industryValue: string) => {
    if (industryOtherValue === industryValue) {
      setTimeout(() => refOtherIndustry.current?.focus())
    } else {
      setTimeout(() => refIndustry.current?.focus())
    }
  }

  return (
    <AuthStepTemplate>
      <Form<RegisterOrgForm>
        validate={(values) => validate({ values, industryOtherValue })}
        onSubmit={submit}
        initialValues={
          orgValues ?? {
            fullName: auth.currentUser?.displayName ?? '',
            orgName: '',
            role: '',
            orgSize: '',
            industry: '',
            otherIndustry: '',
          }
        }
      >
        {({ handleSubmit, values, valid, form }) => (
          <form onSubmit={handleSubmit} className='flex flex-col gap-8'>
            <AuthStepTemplate.Header className='gap-1'>
              <AuthStepTemplate.H2 keyName='auth.steps.orgForm.title' />
              <AuthStepTemplate.RequiredFieldsMessage />
            </AuthStepTemplate.Header>
            <div className='flex w-full flex-col gap-2'>
              <Field name='fullName'>
                {({ input, meta }) => (
                  <div>
                    <Label
                      htmlFor={input.name}
                      error={meta.submitFailed && meta.invalid}
                      variant='registration-field'
                    >
                      <T keyName='auth.steps.orgForm.fullName.label' />
                      <LabelAsterisk />
                    </Label>
                    <Input
                      autoFocus
                      placeholder={t('auth.steps.orgForm.fullName.placeholder')}
                      maxLength={MAX_INPUT_CHARS}
                      error={meta.submitFailed && meta.invalid}
                      {...input}
                    />
                  </div>
                )}
              </Field>
              <Field name='orgName'>
                {({ input, meta }) => (
                  <div>
                    <Label
                      htmlFor={input.name}
                      error={meta.submitFailed && meta.invalid}
                      variant='registration-field'
                    >
                      <T keyName='auth.steps.orgForm.orgName.label' />
                      <LabelAsterisk />
                    </Label>
                    <Input
                      error={meta.submitFailed && meta.invalid}
                      placeholder={t('auth.steps.orgForm.orgName.placeholder')}
                      maxLength={MAX_INPUT_CHARS}
                      {...input}
                    />
                  </div>
                )}
              </Field>
              <Field name='role'>
                {({ input, meta }) => (
                  <div>
                    <Label
                      htmlFor={input.name}
                      error={meta.submitFailed && meta.invalid}
                      variant='registration-field'
                    >
                      <T keyName='auth.steps.orgForm.role.label' />
                      <LabelAsterisk />
                    </Label>
                    <Input
                      error={meta.submitFailed && meta.invalid}
                      placeholder={t('auth.steps.orgForm.role.placeholder')}
                      maxLength={MAX_INPUT_CHARS}
                      {...input}
                    />
                  </div>
                )}
              </Field>
              <Field name='orgSize'>
                {({ input, meta }) => (
                  <div>
                    <Label
                      htmlFor={input.name}
                      error={meta.submitFailed && meta.invalid}
                      variant='registration-field'
                    >
                      <T keyName='auth.steps.orgForm.orgSize.label' />
                      <LabelAsterisk />
                    </Label>
                    <Select name={input.name} value={input.value} onValueChange={input.onChange}>
                      <SelectTrigger error={meta.submitFailed && meta.invalid} isDirty={meta.dirty}>
                        <SelectValue placeholder={t('auth.steps.orgForm.orgSize.placeholder')} />
                      </SelectTrigger>
                      <SelectContent>
                        {ORG_SIZE_KEYS.map((key) => (
                          <SelectItemFromKey key={key} translationKey={key} />
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                )}
              </Field>
              <Field name='industry'>
                {({ input, meta }) => (
                  <div>
                    <Label
                      htmlFor={input.name}
                      error={meta.submitFailed && meta.invalid}
                      variant='registration-field'
                    >
                      <T keyName='auth.steps.orgForm.industry.label' />
                      <LabelAsterisk />
                    </Label>
                    <Select
                      name={input.name}
                      value={input.value}
                      onValueChange={(value) => {
                        input.onChange(value)
                        focusIndustryField(value)
                        form.change('otherIndustry')
                      }}
                    >
                      <SelectTrigger
                        ref={refIndustry}
                        isDirty={meta.dirty}
                        error={meta.submitFailed && meta.invalid}
                      >
                        <SelectValue placeholder={t('auth.steps.orgForm.industry.placeholder')} />
                      </SelectTrigger>
                      <SelectContent onCloseAutoFocus={(e) => e.preventDefault()}>
                        {INDUSTRY_OPTION_KEYS.map((key) => (
                          <SelectItemFromKey key={key} translationKey={key} />
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                )}
              </Field>
              {values.industry === industryOtherValue && (
                <Field name='otherIndustry'>
                  {({ input, meta }) => (
                    <Input
                      {...input}
                      placeholder={t('auth.steps.orgForm.industry.other.placeholder')}
                      maxLength={MAX_INPUT_CHARS}
                      ref={refOtherIndustry}
                      error={meta.submitFailed && meta.invalid}
                    />
                  )}
                </Field>
              )}
              <AuthStepTemplate.ButtonRow className='pt-6'>
                <Button
                  variant='square-outline'
                  size='sm'
                  className='w-full'
                  onClick={() => back(values)}
                >
                  <T keyName='auth.steps.common.buttons.back' />
                </Button>
                <Button
                  type='submit'
                  variant='square'
                  size='sm'
                  disabled={!valid}
                  className='w-full'
                >
                  <T keyName='auth.steps.common.buttons.next' />
                </Button>
              </AuthStepTemplate.ButtonRow>
            </div>
          </form>
        )}
      </Form>
      <AuthStepTemplate.Footer />
    </AuthStepTemplate>
  )
}

// 👇 form validation

const MAX_INPUT_CHARS = 200

function validateField({
  errors,
  key,
  value,
}: {
  key: RegisterOrgFormKeys
  value?: string
  errors: RegisterOrgFormErrors
}) {
  if (!value) errors[key] = true
  else if (value.length > MAX_INPUT_CHARS) {
    errors[key] = `Character limit of ${MAX_INPUT_CHARS} exceeded`
  }
}

function validate({
  values,
  industryOtherValue,
}: {
  values: RegisterOrgForm
  industryOtherValue: string
}): RegisterOrgFormErrors {
  const errors: RegisterOrgFormErrors = {}

  for (const [key, value] of Object.entries(values) as Array<
    [RegisterOrgFormKeys, string | undefined]
  >) {
    if (key === 'otherIndustry') continue
    if (key === 'industry' && industryOtherValue === value) {
      validateField({ errors, key: 'otherIndustry', value: values.otherIndustry })
    }

    validateField({ errors, key, value })
  }

  return errors
}

// 👇 select option keys

export const ORG_SIZE_KEYS: TranslationKey[] = [
  'auth.steps.orgForm.orgSize.options.0',
  'auth.steps.orgForm.orgSize.options.1',
  'auth.steps.orgForm.orgSize.options.2',
  'auth.steps.orgForm.orgSize.options.3',
  'auth.steps.orgForm.orgSize.options.4',
]
const OTHER_INDUSTRY_OPTION: TranslationKey = 'auth.steps.orgForm.industry.options.11'
const INDUSTRY_OPTION_KEYS: TranslationKey[] = [
  'auth.steps.orgForm.industry.options.0',
  'auth.steps.orgForm.industry.options.1',
  'auth.steps.orgForm.industry.options.2',
  'auth.steps.orgForm.industry.options.3',
  'auth.steps.orgForm.industry.options.4',
  'auth.steps.orgForm.industry.options.5',
  'auth.steps.orgForm.industry.options.6',
  'auth.steps.orgForm.industry.options.7',
  'auth.steps.orgForm.industry.options.8',
  'auth.steps.orgForm.industry.options.9',
  'auth.steps.orgForm.industry.options.10',
  OTHER_INDUSTRY_OPTION,
]
