import { type ClassValue, clsx } from 'clsx'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import { twMerge } from 'tailwind-merge'

dayjs.extend(LocalizedFormat)

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function formatDate(
  date: number | string,
  options: { template?: string } = { template: 'LL' },
) {
  const dateNumber = typeof date === 'string' ? parseInt(date) : date

  return dayjs(dateNumber * 1000).format(options.template)
}
