import { auth } from '@/app/_auth/auth'
import { useAuth, useOnAuthStateChanged } from '@/app/_auth/auth-hooks'
import { AuthStep } from '@/app/_auth/auth-step-navigator'
import { AuthStepTemplate } from '@/app/_auth/steps/_common/auth-step-template'
import { TierID } from '@/app/billing/_common/use-tier-data'
import { toast } from '@/components/ui/toaster'
import { handleRequest, USERS_API } from '@/lib'
import EmergenceLoader from '@/styles/animations/emergence-loader.json'
import { useMutation } from '@tanstack/react-query'
import { useTranslate } from '@tolgee/react'
import { signOut } from 'firebase/auth'
import React from 'react'

const Lottie = React.lazy(() => import('lottie-light-react'))

export function GetEmergenceUserStep() {
  const { t } = useTranslate()
  const { setStep, setUser } = useAuth()
  const { mutateAsync } = useGetEmergenceUser()

  useOnAuthStateChanged({
    onLogin: () => {
      mutateAsync().then(({ user, nextStep }) => {
        setUser(user)
        setStep(nextStep)
      })
    },
  })

  return (
    <AuthStepTemplate hideLogo>
      <Lottie title={t('auth.steps.loading.a11y')} animationData={EmergenceLoader} />
    </AuthStepTemplate>
  )
}

type GetEmergenceUserResponse = {
  nextStep: AuthStep | null
  user: EmergenceUser | null
}

function useGetEmergenceUser() {
  return useMutation({
    retry: 3,
    retryDelay: 250,
    mutationKey: ['get-emergence-user'],
    mutationFn: async (): Promise<GetEmergenceUserResponse> => {
      try {
        const response = await handleRequest(USERS_API.USERS)

        if (response.status === 404) {
          return { nextStep: AuthStep.RegisterOrg, user: null }
        }

        if (response.status === 200) {
          const userData = (await response.json()) as EmergenceUserAPIResponse
          const emergenceUser = portEmergenceUser(userData)

          if (emergenceUser.accountStatus === 'DEACTIVATED') {
            return { nextStep: AuthStep.Deactivated, user: emergenceUser }
          }

          return { nextStep: null, user: emergenceUser }
        }

        throw new Error('FAILED_TO_FETCH_EMERGENCE_USER_DATA')
      } catch {
        await signOut(auth)

        toast.error('Error fetching account information. Please login again.')

        return { nextStep: AuthStep.Create, user: null }
      }
    },
  })
}

type EmergenceUserAPIResponse = {
  name: string
  email: string
  organization: string
  role: string
  accountStatus: 'ACTIVE' | 'DEACTIVATED' | 'CLOSED'
  createdAt: string
  lastUpdatedAt: string
  tier: TierID
  welcome_message: boolean
}

export type EmergenceUser = Omit<EmergenceUserAPIResponse, 'name'> & {
  uid: string
  firstName?: string
  lastName?: string
  fullName?: string
  photoURL?: string
}

function portEmergenceUser({ name, ...emUserData }: EmergenceUserAPIResponse): EmergenceUser {
  // check required fields
  const uid = auth.currentUser?.uid

  if (!emUserData.email) throw new Error('EMAIL_NOT_FOUND')
  if (!uid) throw new Error('UID_NOT_FOUND')

  const emergenceUser: EmergenceUser = {
    uid,
    ...emUserData,
  }

  const fullName = name ?? auth.currentUser?.displayName
  // format name
  if (fullName) {
    emergenceUser.fullName = fullName

    const nameArr = fullName.split(' ')
    const firstName = nameArr[0]

    emergenceUser.firstName = firstName

    if (nameArr.length > 1) {
      const lastName = nameArr.at(-1)

      emergenceUser.lastName = lastName
    }
  }

  // get photo url
  if (auth.currentUser?.photoURL) emergenceUser.photoURL = auth.currentUser.photoURL

  return emergenceUser
}
