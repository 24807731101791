/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'

// Create Virtual Routes

const HistoryLazyImport = createFileRoute('/history')()
const AccountLazyImport = createFileRoute('/account')()
const IndexLazyImport = createFileRoute('/')()
const WorkflowsIndexLazyImport = createFileRoute('/workflows/')()
const BillingIndexLazyImport = createFileRoute('/billing/')()
const WorkflowsWorkflowIdIndexLazyImport = createFileRoute(
  '/workflows/$workflowId/',
)()
const BillingPlanIndexLazyImport = createFileRoute('/billing/plan/')()
const BillingPlanEnterpriseContactIndexLazyImport = createFileRoute(
  '/billing/plan/enterprise-contact/',
)()
const BillingPlanEnterpriseContactThankYouLazyImport = createFileRoute(
  '/billing/plan/enterprise-contact/thank-you',
)()

// Create/Update Routes

const HistoryLazyRoute = HistoryLazyImport.update({
  id: '/history',
  path: '/history',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/history.lazy').then((d) => d.Route))

const AccountLazyRoute = AccountLazyImport.update({
  id: '/account',
  path: '/account',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/account.lazy').then((d) => d.Route))

const IndexLazyRoute = IndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/index.lazy').then((d) => d.Route))

const WorkflowsIndexLazyRoute = WorkflowsIndexLazyImport.update({
  id: '/workflows/',
  path: '/workflows/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/workflows/index.lazy').then((d) => d.Route),
)

const BillingIndexLazyRoute = BillingIndexLazyImport.update({
  id: '/billing/',
  path: '/billing/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/billing/index.lazy').then((d) => d.Route))

const WorkflowsWorkflowIdIndexLazyRoute =
  WorkflowsWorkflowIdIndexLazyImport.update({
    id: '/workflows/$workflowId/',
    path: '/workflows/$workflowId/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/workflows/$workflowId/index.lazy').then((d) => d.Route),
  )

const BillingPlanIndexLazyRoute = BillingPlanIndexLazyImport.update({
  id: '/billing/plan/',
  path: '/billing/plan/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/billing/plan/index.lazy').then((d) => d.Route),
)

const BillingPlanEnterpriseContactIndexLazyRoute =
  BillingPlanEnterpriseContactIndexLazyImport.update({
    id: '/billing/plan/enterprise-contact/',
    path: '/billing/plan/enterprise-contact/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/billing/plan/enterprise-contact/index.lazy').then(
      (d) => d.Route,
    ),
  )

const BillingPlanEnterpriseContactThankYouLazyRoute =
  BillingPlanEnterpriseContactThankYouLazyImport.update({
    id: '/billing/plan/enterprise-contact/thank-you',
    path: '/billing/plan/enterprise-contact/thank-you',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/billing/plan/enterprise-contact/thank-you.lazy').then(
      (d) => d.Route,
    ),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/account': {
      id: '/account'
      path: '/account'
      fullPath: '/account'
      preLoaderRoute: typeof AccountLazyImport
      parentRoute: typeof rootRoute
    }
    '/history': {
      id: '/history'
      path: '/history'
      fullPath: '/history'
      preLoaderRoute: typeof HistoryLazyImport
      parentRoute: typeof rootRoute
    }
    '/billing/': {
      id: '/billing/'
      path: '/billing'
      fullPath: '/billing'
      preLoaderRoute: typeof BillingIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/workflows/': {
      id: '/workflows/'
      path: '/workflows'
      fullPath: '/workflows'
      preLoaderRoute: typeof WorkflowsIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/billing/plan/': {
      id: '/billing/plan/'
      path: '/billing/plan'
      fullPath: '/billing/plan'
      preLoaderRoute: typeof BillingPlanIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/workflows/$workflowId/': {
      id: '/workflows/$workflowId/'
      path: '/workflows/$workflowId'
      fullPath: '/workflows/$workflowId'
      preLoaderRoute: typeof WorkflowsWorkflowIdIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/billing/plan/enterprise-contact/thank-you': {
      id: '/billing/plan/enterprise-contact/thank-you'
      path: '/billing/plan/enterprise-contact/thank-you'
      fullPath: '/billing/plan/enterprise-contact/thank-you'
      preLoaderRoute: typeof BillingPlanEnterpriseContactThankYouLazyImport
      parentRoute: typeof rootRoute
    }
    '/billing/plan/enterprise-contact/': {
      id: '/billing/plan/enterprise-contact/'
      path: '/billing/plan/enterprise-contact'
      fullPath: '/billing/plan/enterprise-contact'
      preLoaderRoute: typeof BillingPlanEnterpriseContactIndexLazyImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexLazyRoute
  '/account': typeof AccountLazyRoute
  '/history': typeof HistoryLazyRoute
  '/billing': typeof BillingIndexLazyRoute
  '/workflows': typeof WorkflowsIndexLazyRoute
  '/billing/plan': typeof BillingPlanIndexLazyRoute
  '/workflows/$workflowId': typeof WorkflowsWorkflowIdIndexLazyRoute
  '/billing/plan/enterprise-contact/thank-you': typeof BillingPlanEnterpriseContactThankYouLazyRoute
  '/billing/plan/enterprise-contact': typeof BillingPlanEnterpriseContactIndexLazyRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexLazyRoute
  '/account': typeof AccountLazyRoute
  '/history': typeof HistoryLazyRoute
  '/billing': typeof BillingIndexLazyRoute
  '/workflows': typeof WorkflowsIndexLazyRoute
  '/billing/plan': typeof BillingPlanIndexLazyRoute
  '/workflows/$workflowId': typeof WorkflowsWorkflowIdIndexLazyRoute
  '/billing/plan/enterprise-contact/thank-you': typeof BillingPlanEnterpriseContactThankYouLazyRoute
  '/billing/plan/enterprise-contact': typeof BillingPlanEnterpriseContactIndexLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexLazyRoute
  '/account': typeof AccountLazyRoute
  '/history': typeof HistoryLazyRoute
  '/billing/': typeof BillingIndexLazyRoute
  '/workflows/': typeof WorkflowsIndexLazyRoute
  '/billing/plan/': typeof BillingPlanIndexLazyRoute
  '/workflows/$workflowId/': typeof WorkflowsWorkflowIdIndexLazyRoute
  '/billing/plan/enterprise-contact/thank-you': typeof BillingPlanEnterpriseContactThankYouLazyRoute
  '/billing/plan/enterprise-contact/': typeof BillingPlanEnterpriseContactIndexLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/account'
    | '/history'
    | '/billing'
    | '/workflows'
    | '/billing/plan'
    | '/workflows/$workflowId'
    | '/billing/plan/enterprise-contact/thank-you'
    | '/billing/plan/enterprise-contact'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/account'
    | '/history'
    | '/billing'
    | '/workflows'
    | '/billing/plan'
    | '/workflows/$workflowId'
    | '/billing/plan/enterprise-contact/thank-you'
    | '/billing/plan/enterprise-contact'
  id:
    | '__root__'
    | '/'
    | '/account'
    | '/history'
    | '/billing/'
    | '/workflows/'
    | '/billing/plan/'
    | '/workflows/$workflowId/'
    | '/billing/plan/enterprise-contact/thank-you'
    | '/billing/plan/enterprise-contact/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexLazyRoute: typeof IndexLazyRoute
  AccountLazyRoute: typeof AccountLazyRoute
  HistoryLazyRoute: typeof HistoryLazyRoute
  BillingIndexLazyRoute: typeof BillingIndexLazyRoute
  WorkflowsIndexLazyRoute: typeof WorkflowsIndexLazyRoute
  BillingPlanIndexLazyRoute: typeof BillingPlanIndexLazyRoute
  WorkflowsWorkflowIdIndexLazyRoute: typeof WorkflowsWorkflowIdIndexLazyRoute
  BillingPlanEnterpriseContactThankYouLazyRoute: typeof BillingPlanEnterpriseContactThankYouLazyRoute
  BillingPlanEnterpriseContactIndexLazyRoute: typeof BillingPlanEnterpriseContactIndexLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexLazyRoute: IndexLazyRoute,
  AccountLazyRoute: AccountLazyRoute,
  HistoryLazyRoute: HistoryLazyRoute,
  BillingIndexLazyRoute: BillingIndexLazyRoute,
  WorkflowsIndexLazyRoute: WorkflowsIndexLazyRoute,
  BillingPlanIndexLazyRoute: BillingPlanIndexLazyRoute,
  WorkflowsWorkflowIdIndexLazyRoute: WorkflowsWorkflowIdIndexLazyRoute,
  BillingPlanEnterpriseContactThankYouLazyRoute:
    BillingPlanEnterpriseContactThankYouLazyRoute,
  BillingPlanEnterpriseContactIndexLazyRoute:
    BillingPlanEnterpriseContactIndexLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/account",
        "/history",
        "/billing/",
        "/workflows/",
        "/billing/plan/",
        "/workflows/$workflowId/",
        "/billing/plan/enterprise-contact/thank-you",
        "/billing/plan/enterprise-contact/"
      ]
    },
    "/": {
      "filePath": "index.lazy.tsx"
    },
    "/account": {
      "filePath": "account.lazy.tsx"
    },
    "/history": {
      "filePath": "history.lazy.tsx"
    },
    "/billing/": {
      "filePath": "billing/index.lazy.tsx"
    },
    "/workflows/": {
      "filePath": "workflows/index.lazy.tsx"
    },
    "/billing/plan/": {
      "filePath": "billing/plan/index.lazy.tsx"
    },
    "/workflows/$workflowId/": {
      "filePath": "workflows/$workflowId/index.lazy.tsx"
    },
    "/billing/plan/enterprise-contact/thank-you": {
      "filePath": "billing/plan/enterprise-contact/thank-you.lazy.tsx"
    },
    "/billing/plan/enterprise-contact/": {
      "filePath": "billing/plan/enterprise-contact/index.lazy.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
