import { useTranslate } from '@tolgee/react'
import { cva, VariantProps } from 'class-variance-authority'
import React from 'react'

export const Spinner = ({
  color,
  size,
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & SpinnerVariantProps) => {
  const { t } = useTranslate()
  return (
    <div
      title={t('shared.loading')}
      className={spinnerVariants({ color, size, className })}
      {...props}
    />
  )
}
type SpinnerVariantProps = VariantProps<typeof spinnerVariants>
const spinnerVariants = cva('animate-spin rounded-full', {
  variants: {
    color: {
      blue: 'border-lilac border-r-indigo-500',
      white: 'border-[rgba(255,255,255,.15)] border-r-[rgba(255,255,255,.85)]',
      black: 'border-[rgba(0,0,0,.15)] border-r-[rgba(0,0,0,.85)]',
    },
    size: {
      xs: 'h-4 w-4 border-2',
      sm: 'h-5 w-5 border-2',
      md: 'h-10 w-10 border-4',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})
